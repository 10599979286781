*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Minion;
  scroll-behavior: smooth;
}

@font-face {
  font-family: Minion;
  src: url("./fonts/MinionPro-Medium.woff");
}

@font-face {
  font-family: Milliard;
  src: url("./fonts/milliard_medium_normal.woff");
}

* {
  color: white;
  font-family: Minion;
}

body {
  background-color: #161b22;
  color: white;
}

.coming-soon {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming-soon h1 {
  font-size: 40px;
}

.header_mobile,
.mobile-menu {
  display: none;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

header a {
  text-decoration: none;
  margin: 0 16px;
  font-weight: 500;
  position: relative;
  transition: 0.6s;
}

.line a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -3px;
  left: 0;
  transition: 0.6s;
}

.line a:hover {
  color: rgb(255, 193, 128, 0.65);
}

.line a:hover:after {
  width: 100%;
  background-color: rgb(255, 193, 128, 0.65);
}

.header-title {
  font-weight: bold;
  font-size: 20px;
}

.logo {
  width: 50px;
  filter: invert(1);
}

.main {
  max-width: 1400px;
  width: 86%;
  margin: 0 auto;
}

.intro {
  margin-top: 100px;
}

.intro h6 {
  color: rgba(255, 193, 128, 0.65);
  font-size: 24px;
  margin-bottom: 8px;
}

.intro h2 {
  color: rgba(255, 193, 128, 0.65);
  font-size: 60px;
}

.intro h4 {
  color: #c5c8cc;
  font-size: 30px;
  font-weight: 400;
}

.more-intro {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  max-width: 1100px;
}

.more-intro svg {
  width: 60px;
  min-width: 26px;
  fill: #c5c8cc;
}

.more-intro p {
  margin-left: 16px;
  font-family: Milliard;
  font-size: 18px;
  color: #c5c8cc;
}

.view-start {
  display: flex;
  margin-top: 26px;
}

header svg {
  cursor: pointer;
}

.view-start a {
  padding: 12px 25px;
  outline: none;
  margin-right: 14px;
  min-width: 220px;
  max-width: 220px;
  width: 100%;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.view-start a:first-child {
  border: 1px solid rgba(255, 193, 128, 0.65);
  color: rgba(255, 193, 128, 0.65);
  background-color: transparent;
  font-size: 16px;
  transition: 0.5s ease-in-out;
}

.view-start a:first-child:hover {
  transform: scale(1.06);
  background-color: #232b35;
}

.view-start a:last-child {
  border: 1px solid rgba(255, 193, 128, 0.65);
  color: #c5c8cc;
  background-color: rgba(255, 194, 128, 0.468);
  /* background-color: rgba(255, 193, 128, 0.65); */
  font-size: 16px;
  transition: 0.5s ease-in-out;
}

.view-start a:last-child:hover {
  transform: scale(1.06);
  background-color: rgba(217, 142, 63, 0.65);
}

.socials {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.socials svg {
  display: block;
  margin-bottom: 14px;
}

.socials hr {
  height: 80px;
  width: 1px;
  border-width: 0;
  background-color: rgb(255, 255, 255);
}

.codementor {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.skills {
  margin-top: 70px;
}

.skills h4 {
  font-size: 20px;
  margin-bottom: 16px;
}

.skills-percent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skills-percent span {
  color: #5eead4;
}

.skills-wrapper {
  display: flex;
  justify-content: space-between;
}

.skills-wrapper .divider {
  border: 1.5px solid rgb(14, 86, 86);
}

.skills-inner {
  width: 47%;
}
.skills-inner .progress {
  background-color: #cde1f8;
  margin: 0.5rem 0 1.2rem 0;
  border-radius: 0;
  height: 0.7rem;
}

.skills-inner .progress .progress-bar {
  height: 0.7rem;
  background-color: rgb(14, 86, 86);
}

.skills-img {
  width: 50%;
  text-align: center;
}

.skills-img img {
  max-width: 380px;
  width: 90%;
}

.contact {
  max-width: 600px;
  width: 100%;
  margin: 60px auto 0 auto;
}

.contact label span {
  color: red;
}

.contact h4 {
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
}

.contact-form {
  width: 100%;
  background-color: #161b22;
  border: 1px solid #3c4656;
  border-left: 6px solid #3c4656;
  padding: 30px;
}

.contact-form input {
  width: 100%;
  height: 42px;
  padding-left: 12px;
  outline: none;
  border: none;
  margin-bottom: 16px;
  border-radius: 2px;
  margin-top: 4px;
  border: 1.6px solid #3c4656;
  background-color: transparent;
  color: #c5c8cc;
  font-weight: 600;
  font-size: 16px;
  transition: 0.5s;
  border-radius: 6px;
}

.contact-form textarea {
  padding-left: 12px;
  padding-top: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 2px;
  margin-top: 4px;
  border: 1.6px solid #3c4656;
  background-color: transparent;
  color: #c5c8cc;
  transition: 0.5s;
  border-radius: 6px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  box-shadow: 0px 0px 6px 2px #3c4656;
}

.contact-form label {
  font-weight: 600;
  color: #c5c8cc;
}

.contact-btn {
  margin-top: 20px;
}

.contact-btn button {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  background-color: rgb(12, 103, 103);
  outline: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 6px;
}

.contact-btn button:hover {
  background-color: rgba(14, 86, 86, 0.862);
}

.projects-wrapper {
  margin-top: 60px;
}

.projects-wrapper h4 {
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
  padding-top: 12px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5%;
  margin-right: -1.5%;
}

.projects .inner {
  width: 30%;
  margin: 40px 1.5%;
  position: relative;
  max-height: 240px;
  background-color: #161b22;
  padding: 6px;
}

.projects .tags {
  padding: 12px 4px;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.projects .tags span {
  border: 1px solid rgb(14, 86, 86);
  margin-right: 6px;
  margin-bottom: 4px;
  border-radius: 9999px;
  background-color: rgba(45, 212, 191, 0.1);
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #5eead4;
}

.projects.design .inner {
  width: 47%;
  max-height: 420px;
}

.projects img {
  object-fit: cover;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  object-position: top;
}

.projects .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 27, 34, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s ease-in-out;
  border: 1px solid #282f3a;
  border-left: 4px solid #282f3a;
  border-top: 4px solid #282f3a;
}

.projects .overlay h5 {
  opacity: 0;
  transition: 0.6s ease-in-out;
  font-size: 2px;
}

.projects .overlay:hover {
  background-color: rgba(22, 27, 34, 0.9);
  border-color: #57667e;
}

.projects .overlay:hover h5 {
  opacity: 1;
  font-size: 18px;
  text-align: center;
}

.design-title {
  margin-top: 60px;
}

footer {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.8px solid rgba(255, 194, 128, 0.36);
  max-width: 1400px;
  width: 86%;
  margin: 60px auto 0 auto;
}

footer img {
  width: 40px;
  margin-right: 10px;
  filter: invert(1);
}

@media screen and (max-width: 768px) {
  .skills-wrapper .divider {
    display: none;
  }

  .main,
  footer {
    width: 80%;
  }
  .intro h2 {
    font-size: 45px;
  }

  .view-start {
    flex-direction: column;
  }

  .view-start a {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .skills-wrapper {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .skills-inner {
    width: 100%;
  }

  .skills-img {
    width: 100%;
  }

  .header_desktop {
    display: none;
  }

  .header_mobile {
    display: flex;
  }

  .logo {
    width: 40px;
  }

  .mobile-menu {
    display: block;
    background-color: #161b22;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 16px;
    transform: translate(800px);
    transition: 1s ease-in;
  }

  .mobile-menu.opened {
    transform: translate(0);
    transition: 1s ease-out;
  }

  .mobile-menu nav {
    margin-top: 16px;
  }

  .mobile-menu nav a {
    display: block;
    margin-bottom: 16px;
    font-size: 16px;
    text-decoration: none;
  }

  .mobile-menu .close {
    display: flex;
    justify-content: flex-end;
  }

  .close svg {
    cursor: pointer;
    transition: 0.5s;
  }

  .close svg:hover {
    background-color: #506176;
  }

  .projects .inner {
    width: 47%;
  }
}

@media screen and (max-width: 480px) {
  .projects .inner,
  .projects.design .inner {
    width: 97%;
    max-height: 230px;
  }
}
